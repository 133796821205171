<template lang="pug">
.min-h-screen.overflow-x-hidden
  heroSection
  sectionBranding
  sectionInformation
  sectionCTA
  sectionComments
  sectionPrices(v-if="showPricesComponent")
  sectionAnalitycs
</template>
<script>
import { defineAsyncComponent } from "vue";

const heroSection = defineAsyncComponent(() =>
  import("@/components/www/home/hero")
);

const sectionBranding = defineAsyncComponent(() =>
  import("@/components/www/home/sectionBranding")
);
const sectionInformation = defineAsyncComponent(() =>
  import("@/components/www/home/sectionInformation")
);
const sectionCTA = defineAsyncComponent(() =>
  import("@/components/www/home/sectionCTA")
);
const sectionComments = defineAsyncComponent(() =>
  import("@/components/www/home/sectionComments")
);
const sectionAnalitycs = defineAsyncComponent(() =>
  import("@/components/www/home/sectionAnalitycs")
);

const sectionPrices = defineAsyncComponent(() =>
  import("@/components/www/home/prices-component.vue")
);

export default {
  name: "Home",
  components: {
    heroSection,
    sectionBranding,
    sectionInformation,
    sectionCTA,
    sectionComments,
    sectionAnalitycs,
    sectionPrices,
  },

  computed: {
    showPricesComponent() {
      return this.$store.getters["ProductsStore/getproductDefault"]?.product_type ==
        "subscription";
    }

  }
};
</script>
<style lang="scss" scoped>
</style>
